/* 
// DISCLAIMER
// 
// Copyright 2019 ArangoDB GmbH, Cologne, Germany
// 
// Author Robert Stam
*/

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.set-organization-tier,
.set-datacluster-unassignable,
.edit-expires-at,
.edit-pencil {
  padding-left: 1em;
  cursor: pointer;
  opacity: 0.2 !important;
  /* Hover off */
  transition-property: opacity !important;
  transition-duration: 3s !important;
  transition-delay: 1s !important;
}

.set-organization-tier:hover,
.set-datacluster-unassignable:hover,
.edit-expires-at:hover,
.edit-pencil:hover {
  opacity: 1 !important;
  /* Hover on */
  transition-property: opacity !important;
  transition-duration: 0.5s !important;
  transition-delay: 0.2s !important;
}

.table-action-buttons {
  opacity: 0.2;
}
